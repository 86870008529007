import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { CollectingItemsService } from 'dm-src/app/modules/collecting/collecting-items.service';
import { ReplaySubject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
    IUpdateOrderItem,
    OrderItem,
    IOrdersService,
    ORDERS_SERVICE_IMPL,
    realRound,
    priceRound,
    ILoggerClientService,
    LOGGER_CLIENT_SERVICE_IMPL,
    OrderStateEventLog,
    EventType,
    ActionType,
} from 'shared';
import { CollectingStatesService } from '../../collecting-states.service';
import { QuantityComponent } from 'shared';

@Component({
    selector: 'app-collect-approval-modal',
    templateUrl: './collect-approval-modal.component.html',
    styleUrls: ['./collect-approval-modal.component.scss'],
})
export class CollectApprovalModalComponent implements OnInit, OnDestroy {
    private _destroy$: ReplaySubject<boolean>;
    public collectionApprovalForm: UntypedFormGroup;
    public orderItem: OrderItem;
    public isSubmitted: boolean;
    public currentQuantityInput: string;
    public optionalDepositProductQuantity = 0;

    public get fields() {
        return this.collectionApprovalForm.controls;
    }

    public get quantityStep(): string {
        return this.orderItem.isBulk ? '.001' : '1';
    }

    public get quantityDecimalNumber(): number {
        return this.orderItem.isBulk ? 3 : 0;
    }

    public get quantityInputMode(): string {
        return this.orderItem.isBulk ? 'decimal' : 'numeric';
    }

    public get validatorPattern(): string {
        return this.orderItem.isBulk
            ? '^((\\d+[\\,\\.]?|[\\,\\.](?=\\d))?\\d{0,3})$'
            : '^([1-9][0-9]*)?$';
    }

    @ViewChild('collectApprovalModal', { static: false })
    private _collectApprovalModal: TemplateRef<any>;

    @ViewChildren('quantityComponent')
    quantity: QuantityComponent;

    constructor(
        private _collectingItemsService: CollectingItemsService,
        public _collectingStatesService: CollectingStatesService,
        @Inject(LOGGER_CLIENT_SERVICE_IMPL)
        private _loggerClientService: ILoggerClientService,
        private _ngbModal: NgbModal,
        private _formBuilder: UntypedFormBuilder,
        @Inject(ORDERS_SERVICE_IMPL) private _ordersService: IOrdersService,
    ) {
        this._destroy$ = new ReplaySubject<boolean>(1);
    }

    ngOnInit(): void {
        this.isSubmitted = false;
        this._collectingItemsService.itemToApprove
            .pipe(skip(1))
            .pipe(takeUntil(this._destroy$))
            .subscribe((orderItem) => {
                this.orderItem = orderItem;

                this.optionalDepositProductQuantity = 0;

                this.initApprovalForm();
                this._ngbModal.open(this._collectApprovalModal, {
                    windowClass: 'modal-holder',
                    centered: true,
                    size: 'sm',
                });
                if (this.orderItem.isBulk) {
                    this.setInputFilter(document.getElementById('quantity'), (value) => {
                        const isValid = /^((\d+[\,\.]?|[\,\.](?=\d))?\d{0,3})$/.test(
                            value
                        );
                        if (value && isValid) {
                            const newValue = (<HTMLInputElement>(
                                document.getElementById('quantity')
                            )).value;
                            this.fields.quantity.setValue(newValue);
                        }
                        return isValid;
                    });
                } else {
                    //only positive int
                    this.setInputFilter(document.getElementById('quantity'), (value) => {
                        const isValid = /^([1-9][0-9]*)?$/.test(value);
                        if (value && isValid) {
                            const newValue = (<HTMLInputElement>(
                                document.getElementById('quantity')
                            )).value;
                            this.fields.quantity.setValue(newValue);
                        }
                        return isValid;
                    });
                }
            });
    }

    ngOnDestroy(): void {
        this._destroy$.next(true);
        this._destroy$.complete();
    }

    public onSubmit(): void {
        if (this.orderItem.isBulk) {
            if (isNaN(this.fields.quantity.value)) {
                this.fields.quantity.setValue(
                    parseFloat(
                        this.fields.quantity.value.replace(',', '.').replace(' ', '')
                    )
                );
            } else {
                this.fields.quantity.setValue(parseFloat(this.fields.quantity.value));
            }
            if (this.fields.quantity.value <= 0)
                this.fields.quantity.setErrors({ pattern: true });
        } else {
            this.fields.quantity.setValue(parseInt(this.fields.quantity.value));
        }

        this._collectingItemsService
            .checkIfOrderModificationIsBlocked(this._collectingStatesService.orderID)
            .subscribe((blocked) => {
                if (blocked) {
                    return;
                } else {
                    this.isSubmitted = true;

                    if (this.collectionApprovalForm.invalid) {
                        this.isSubmitted = false;
                        return;
                    }

                    this.checkApprovedValues();
                }
            });
    }

    private initApprovalForm(): void {
        this.collectionApprovalForm = this._formBuilder.group({
            quantity: [
                this.orderItem.isWerwProduct ? this.orderItem.quantity : 0,
                [Validators.pattern(this.validatorPattern)],
            ],
            unitPrice: [this.orderItem.unitPrice, Validators.required],
            orderedQuantity: this.orderItem.orderedQuantity,
            optionalDepositProductQuantity: this.optionalDepositProductQuantity,
            itemName: this.orderItem.orderItemName,
        });
    }

    private checkApprovedValues(): void {
        if (
            this.orderItem.unitPrice === this.fields.unitPrice.value &&
            this.orderItem.quantity === this.fields.quantity.value &&
            this.orderItem.collectedQuantity === this.fields.quantity.value &&
            this.orderItem.providerOfflineProductID === null &&
            !this.orderItem.isBulk
        ) {
            this.collectItem();
        } else {
            const orderItemData: IUpdateOrderItem = {
                orderItemID: this.orderItem.orderItemID,
                orderItemUnit: this.orderItem.unit,
                orderItemQuantity: this.fields.quantity.value,
                isCollecting: true,
                orderItemUnitPrice: this.fields.unitPrice.value,
                offlineProductID: this.orderItem.providerOfflineProductID,
                vat: this.orderItem.vat,
                isBulk: this.orderItem.isBulk,
                isWerwProduct: this.orderItem.isWerwProduct,
                providerID: this._collectingStatesService.providerID,
                productProviderID: this.orderItem.productProviderID,
                optionalDepositProductQuantity: this.optionalDepositProductQuantity,
            };
            this._ordersService.updateOrderItem(orderItemData).subscribe((response) => {
                this.isSubmitted = false;
                if (response.status === 200) {
                    if (
                        this.orderItem.orderItemDepositProductList &&
                        this.orderItem.orderItemDepositProductList.length > 0
                    ) {
                        if (
                            this.orderItem.orderItemDepositProductList.find(
                                (x) => !x.depositUnitTypeIsOptional
                            )
                        )
                            this.orderItem.orderItemDepositProductList.find(
                                (x) => !x.depositUnitTypeIsOptional
                            ).collectedQuantity = this.fields.quantity.value;

                        if (
                            this.orderItem.orderItemDepositProductList.find(
                                (x) => x.depositUnitTypeIsOptional
                            )
                        )
                            this.orderItem.orderItemDepositProductList.find(
                                (x) => x.depositUnitTypeIsOptional
                            ).collectedQuantity = this.optionalDepositProductQuantity;
                    }

                    this.orderItem.unitPrice = this.fields.unitPrice.value;
                    this.orderItem.quantity = realRound(this.fields.quantity.value, 3);
                    this.orderItem.price = priceRound(
                        this.orderItem.unitPrice * this.orderItem.quantity
                    );
                    this.orderItem.totalPrice =
                        this.orderItem.quantity * this.orderItem.unitPrice;
                    this.collectItem();
                }
            });
        }
    }

    changeDepositValue(event: any): void {
        this.optionalDepositProductQuantity = event;
    }

    private collectItem(): void {
        var orderStateEventLog = new OrderStateEventLog();

        orderStateEventLog.eventType = EventType.OrderItemStateChanged;
        orderStateEventLog.actionType = ActionType.AddOrderItemToBasketWithQuantity;
        orderStateEventLog.fromStateJson = null;
        orderStateEventLog.toStateJson = JSON.stringify(this.orderItem);
        orderStateEventLog.orderID = this._collectingStatesService.orderID;
        orderStateEventLog.orderCode = this._collectingStatesService.orderCode;

        this._loggerClientService.addOrderStateEventLog(orderStateEventLog).subscribe();

        this._collectingItemsService.collectItem(this.orderItem);

        this._ngbModal.dismissAll();
        this.isSubmitted = false;
    }

    setInputFilter(textbox: Element, inputFilter: (value: string) => boolean): void {
        [
            'input',
            'keydown',
            'keyup',
            'mousedown',
            'mouseup',
            'select',
            'contextmenu',
            'drop',
        ].forEach(function (event) {
            textbox.addEventListener(
                event,
                function (
                    this: (HTMLInputElement | HTMLTextAreaElement) & {
                        oldValue: string;
                        oldSelectionStart: number | null;
                        oldSelectionEnd: number | null;
                    }
                ) {
                    if (inputFilter(this.value)) {
                        this.oldValue = this.value;
                        this.oldSelectionStart = this.selectionStart;
                        this.oldSelectionEnd = this.selectionEnd;
                    } else if (Object.prototype.hasOwnProperty.call(this, 'oldValue')) {
                        this.value = this.oldValue;
                        if (
                            this.oldSelectionStart !== null &&
                            this.oldSelectionEnd !== null
                        ) {
                            this.setSelectionRange(
                                this.oldSelectionStart,
                                this.oldSelectionEnd
                            );
                        }
                    } else {
                        this.value = '';
                    }
                }
            );
        });
    }
}
