import { PaymentState } from '../types/payment-state';
import { PaymentType } from '../types/payment-type';
import { BagType } from '../types/bag.type';
import { TimeslotDto } from '../dtos/timeslot.dto';
import { SendBirdChatChannel } from './sendbird-chat-channel';

export class TaskListItem {
    public deliveryTaskID: string;
    public userID: string;
    public username: string;
    public orderItemNumber: number;
    public orderID: number;
    public orderQrCode: string;
    public orderCode: string;
    public deliveryTypeID: string;
    public customerName: string;
    public deliveryAddress: string;
    public taskName: string;
    public providerShop: string;
    public customerZipCode: string;
    public providerName: string;
    public deliveryDeadline: Date;
    public estimatedStartDate: Date;
    public estimatedEndDate: Date;
    public realStartDate: Date;
    public realEndDate: Date;
    public deliveryTimeSlotEnd: number;
    public createdAt: Date;
    public state: string;
    public type: string;
    public notes: string;
    public superOrderCode: string;
    public isSuperOrder: boolean;
    public stateChangedAt: Date;
    public paymentType: PaymentType;
    public isInCollection = false;
    public zipCode: any; //Qickfix
    public paymentTypeID: string;
    public consultationType: string;
    public usePaperBag: boolean;
    public supplierRegion: string;
    public paymentState: PaymentState;
    public cityName: string;
    public timeSlotVisualShiftMinutes: number;
    public realProviderShopName: string;
    public originalProviderShopName: string;
    public paperBagType: BagType;
    public timeslotLength: number;
    public supportComment: string;
    public timeSlotStartHour: number;
    public timeSlotEndHour: number;
    public timeSlotStartHourDate: Date;
    public timeSlotEndHourDate: Date;
    public timeSlot?: TimeslotDto;
    public deliveryTimeSlot: string;
    public sendBirdChatChannel?: SendBirdChatChannel;
}
