<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-8">
            <div class="card">
                <div class="card-header">
                    {{ 'deposit.title' | translate }}
                </div>
                <div class="card-body">
                    <nav ngbNav #nav="ngbNav" class="nav-tabs">
                        <ng-container ngbNavItem *appHasRoles="['picker', 'deliverer']"
                            ><a ngbNavLink>{{
                                'deposit.voucher-tab-title' | translate
                            }}</a>
                            <ng-template ngbNavContent>
                                <div class="mt-3">
                                    <button
                                        class="btn btn-primary"
                                        (click)="showDepositVoucherPhotoUploaderModal()"
                                    >
                                        {{ 'deposit.voucher-button-title' | translate }}
                                    </button>
                                </div>
                            </ng-template>
                        </ng-container>
                    </nav>
                    <div [ngbNavOutlet]="nav"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-deposit-voucher-photo-uploader-modal></app-deposit-voucher-photo-uploader-modal>
<app-deposit-voucher-barcode-reader-modal
    (onBarcodeRead)="setBarcode($event)"
></app-deposit-voucher-barcode-reader-modal>
<app-deposit-voucher-barcode-checking-modal></app-deposit-voucher-barcode-checking-modal>
