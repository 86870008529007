<div class="container">
    <div class="card card-container">
        <img
            id="profile-img"
            class="profile-img-card"
            src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
        />
        <p id="profile-name" class="profile-name-card">
            {{ 'common.login' | translate }}
        </p>
        <lib-flash-message id="login"></lib-flash-message>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="form-signin">
            <span id="reauth-email" class="reauth-email"></span>
            <input
                type="email"
                id="inputEmail"
                formControlName="email"
                class="form-control"
                placeholder="{{ 'common.email' | translate }}"
                required
                autofocus
                [ngClass]="{ 'is-invalid': isSubmitted && fields.email.errors }"
            />
            <div *ngIf="isSubmitted && fields.email.errors" class="invalid-feedback">
                <div *ngIf="fields.email.errors.required">Email is required</div>
            </div>
            <input
                type="password"
                id="inputPassword"
                formControlName="password"
                class="form-control"
                placeholder="{{ 'common.password' | translate }}"
                required
                [ngClass]="{ 'is-invalid': isSubmitted && fields.password.errors }"
            />
            <div *ngIf="isSubmitted && fields.password.errors" class="invalid-feedback">
                <div *ngIf="fields.password.errors.required">Password is required</div>
            </div>
            <div id="remember" class="checkbox">
                <label>
                    <input
                        type="checkbox"
                        value="remember-me"
                        formControlName="rememberMe"
                    />
                    Remember me
                </label>
            </div>
            <button class="btn btn-lg btn-primary btn-block btn-signin" type="submit">
                Sign in
            </button>
        </form>
        <a [routerLink]="['/login/forgotpassword']" class="forgot-password">
            {{ 'login.forgot-password' | translate }}</a
        >
        <hr />
        <div class="language-chooser col-12 mt-2">
            <h5>Select your language</h5>
            <img
                src="https://cdn.britannica.com/55/1455-004-5897143C/Flag-Hungary.jpg"
                (click)="switchToLanguage('hu')"
                alt="Country"
                class="language-selector m-1"
            />
            <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Flag_of_Germany.svg/1280px-Flag_of_Germany.svg.png"
                (click)="switchToLanguage('de')"
                alt="Country"
                class="language-selector m-1"
            />
            <img
                src="https://clipart-library.com/img/709150.png"
                (click)="switchToLanguage('en')"
                alt="Country"
                class="language-selector m-1"
            />
            <img
                src="https://cdn.countryflags.com/thumbs/ukraine/flag-400.png"
                (click)="switchToLanguage('ua')"
                alt="Country"
                class="language-selector m-1"
            />
            <img
                src="https://weshopsiteimages.blob.core.windows.net/images/l1.png"
                (click)="switchToLanguage('l1')"
                alt="Country"
                class="language-selector m-1"
            />
            <img
                src="https://weshopsiteimages.blob.core.windows.net/images/l2.png"
                (click)="switchToLanguage('l2')"
                alt="Country"
                class="language-selector m-1"
            />

            <img
                src="https://weshopsiteimages.blob.core.windows.net/images/l3.png"
                (click)="switchToLanguage('l3')"
                alt="Country"
                class="language-selector m-1"
            />

            <img
                src="https://weshopsiteimages.blob.core.windows.net/images/l4.png"
                (click)="switchToLanguage('l4')"
                alt="Country"
                class="language-selector m-1"
            />

            <img
                src="https://weshopsiteimages.blob.core.windows.net/images/l5.png"
                (click)="switchToLanguage('l5')"
                alt="Country"
                class="language-selector m-1"
            />

            <img
                src="https://weshopsiteimages.blob.core.windows.net/images/l6.png"
                (click)="switchToLanguage('l6')"
                alt="Country"
                class="language-selector m-1"
            />

            <img
                src="https://weshopsiteimages.blob.core.windows.net/images/l7.png"
                (click)="switchToLanguage('l7')"
                alt="Country"
                class="language-selector m-1"
            />

            <img
                src="https://weshopsiteimages.blob.core.windows.net/images/l8.png"
                (click)="switchToLanguage('l8')"
                alt="Country"
                class="language-selector m-1"
            />

            <img
                src="https://weshopsiteimages.blob.core.windows.net/images/l9.png"
                (click)="switchToLanguage('l9')"
                alt="Country"
                class="language-selector m-1"
            />

            <img
                src="https://weshopsiteimages.blob.core.windows.net/images/l10.png"
                (click)="switchToLanguage('l10')"
                alt="Country"
                class="language-selector m-1"
            />
        </div>
        <hr />
        <div class="col-12">
            <h5>Change country</h5>
            <img
                *ngIf="showCountrySelector"
                [src]="selectedCountryTld"
                (click)="handleCountryChooser()"
                alt="Country"
                class="country-selector"
            />
        </div>
    </div>
</div>
