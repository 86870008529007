import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { AppSettings } from 'shared';
import { L10n, loadCldr, setCulture } from '@syncfusion/ej2-base';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-scheduler-base',
    template: ``,
})
export class SchedulerBaseComponent implements OnInit, OnDestroy {
    protected _destroy$: ReplaySubject<boolean>;

    constructor(protected httpClient: HttpClient) {
        this._destroy$ = new ReplaySubject<boolean>(1);
    }

    public ngOnInit(): void {
        const defaultLocale =
            localStorage.getItem('defaultLocale') ?? AppSettings.get('defaultLocale');
        const defaultCulture = AppSettings.get('defaultCulture');

        this.changeScheduerLocale(defaultLocale, defaultCulture);
    }

    public ngOnDestroy(): void {
        this._destroy$.next(true);
        this._destroy$.complete();
    }

    protected changeScheduerLocale(locale: string, culture: string): void {
        const jsonLoads = [
            this.loadJSONFile('ej2', locale),
            this.loadJSONFile('ca-gregorian', locale),
            this.loadJSONFile('timeZoneNames', locale),
            this.loadJSONFile('numbers', locale),
            this.loadJSONFile('numberingSystems'),
            this.loadJSONFile('ca-worktimes', locale),
        ];

        forkJoin(jsonLoads).subscribe(
            ([
                EJ2_LOCALE,
                gregorian,
                timeZoneNames,
                numbers,
                numberingSystems,
                worktimesCommon,
            ]) => {
                loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);
                L10n.load({ [culture]: EJ2_LOCALE[locale] });
                L10n.load(worktimesCommon);
                setCulture(culture);
            }
        );
    }

    private loadJSONFile(filename: string, locale = ''): Observable<any> {
        if (locale.length > 0) {
            locale = '.' + locale;
        }
        return this.httpClient.get(`assets/i18n/${filename}${locale}.json`);
    }
}
