import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from 'dm-src/app/modules/dashboard/dashboard.component';
import { AuthGuard } from 'shared';
import { OrdersComponent } from 'dm-src/app/modules/orders/orders.component';
import { AccountsComponent } from 'dm-src/app/modules/accounts/accounts.component';
import { ConfirmRegistrationComponent } from 'dm-src/app/modules/confirm-registration/confirm-registration.component';
import { LoginComponent } from 'dm-src/app/modules/login/login.component';
import { LogoutComponent } from 'dm-src/app/modules/login/logout.component';
import { RegistrationComponent } from 'dm-src/app/modules/registration/registration.component';
import { TasksComponent } from 'dm-src/app/modules/tasks/tasks.component';
import { MyTasksComponent } from 'dm-src/app/modules/my-tasks/my-tasks.component';
import { TaskEditComponent } from 'dm-src/app/modules/tasks/task.edit.component';
import { CollectingComponent } from 'dm-src/app/modules/collecting/collecting.component';
import { ProviderOrdersComponent } from 'dm-src/app/modules/provider-orders/provider-orders.component';
import { AdminGuard } from 'shared';
import { ProviderAdminGuard } from 'shared';
import { TaskGeneratorComponent } from 'dm-src/app/modules/task-generator/task-generator.component';
import { RoleGuard } from 'shared';
import { UserRole } from 'shared';
import { MyWorktimesComponent } from 'dm-src/app/modules/my-worktimes/my-worktimes.component';
import { MyAccountComponent } from 'dm-src/app/modules/my-account/my-account.component';
import { SupplierResourcesComponent } from 'dm-src/app/modules/supplier-resources/supplier-resources.component'; // CLI imports router
import { TourPlannerComponent } from 'dm-src/app/modules/tour-planner/tour-planner.component'; // CLI imports router
import { SupplierRegionsComponent } from './modules/supplier-regions/supplier-regions.component';
import { InstantDeliveryComponent } from 'dm-src/app/modules/instant-delivery/instant-delivery.component';
import { ForgotPasswordComponent } from './modules/login/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './modules/login/change-password/change-password.component';
import { SupplierTrackingComponent } from './modules/supplier-tracking/supplier-tracking.component';
import { SupplierControlComponent } from './modules/supplier-control/supplier-control.component';
import { DailyReviewComponent } from './modules/daily-review/daily-review.component';
import { PermissionRequestWindowComponent } from 'dm-src/app/modules/permission-request-window/permission-request-window.component';
import { KnowledgeBaseComponent } from './modules/knowledge-base/knowledge-base.component';

const routes: Routes = [
    { path: '', component: DashboardComponent, pathMatch: 'full' },
    { path: 'login/:usr/:pw', component: LoginComponent, pathMatch: 'full' },
    { path: 'login', component: LoginComponent, pathMatch: 'full' },
    {
        path: 'logout',
        canActivate: [AuthGuard],
        component: LogoutComponent,
        pathMatch: 'full',
    },
    {
        path: 'login/forgotpassword',
        component: ForgotPasswordComponent,
        pathMatch: 'full',
    },
    { path: 'login/changepassword', component: ChangePasswordComponent },
    {
        path: 'orders',
        canActivate: [AdminGuard],
        component: OrdersComponent,
        pathMatch: 'full',
    },
    {
        path: 'provider/orders',
        canActivate: [ProviderAdminGuard],
        component: ProviderOrdersComponent,
        pathMatch: 'full',
    },
    {
        path: 'accounts',
        canActivate: [AdminGuard],
        component: AccountsComponent,
        pathMatch: 'full',
    },
    {
        path: 'accounts/confirm-registration',
        component: ConfirmRegistrationComponent,
        pathMatch: 'full',
    },
    { path: 'registration', component: RegistrationComponent, pathMatch: 'full' },
    {
        path: 'tasks',
        canActivate: [AdminGuard],
        component: TasksComponent,
        pathMatch: 'full',
    },
    {
        path: 'tour-planner',
        canActivate: [AdminGuard],
        component: TourPlannerComponent,
        pathMatch: 'full',
    },
    {
        path: 'task-generator',
        canActivate: [RoleGuard],
        component: TaskGeneratorComponent,
        data: { roles: [UserRole.Picker, UserRole.Deliverer] },
        pathMatch: 'full',
    },
    {
        path: 'my-tasks',
        canActivate: [RoleGuard],
        component: MyTasksComponent,
        data: {
            roles: [UserRole.Administrator, UserRole.Picker, UserRole.Deliverer],
        },
        pathMatch: 'full',
    },
    {
        path: 'instant-delivery',
        canActivate: [RoleGuard],
        component: InstantDeliveryComponent,
        data: {
            roles: [UserRole.Administrator, UserRole.InstantDeliverer],
        },
        pathMatch: 'full',
    },
    {
        path: 'my-worktimes',
        canActivate: [RoleGuard],
        component: MyWorktimesComponent,
        data: {
            roles: [UserRole.Administrator, UserRole.Picker, UserRole.Deliverer],
        },
        pathMatch: 'full',
    },
    {
        path: 'my-account',
        canActivate: [AuthGuard],
        component: MyAccountComponent,
        pathMatch: 'full',
    },
    {
        path: 'supplier-resources',
        canActivate: [AdminGuard],
        component: SupplierResourcesComponent,
        pathMatch: 'full',
    },
    {
        path: 'supplier-regions',
        canActivate: [RoleGuard],
        component: SupplierRegionsComponent,
        data: {
            roles: [UserRole.Administrator, UserRole.Supporter],
        },
        pathMatch: 'full',
    },
    {
        path: 'tasks/new',
        canActivate: [AdminGuard],
        component: TaskEditComponent,
        pathMatch: 'full',
    },
    {
        path: 'tasks/edit/:{taskId}',
        canActivate: [AdminGuard],
        component: TaskEditComponent,
        pathMatch: 'full',
    },
    {
        path: 'collecting/:{taskId}/:{orderId}',
        canActivate: [AuthGuard],
        component: CollectingComponent,
        pathMatch: 'full',
    },
    {
        path: 'tracking',
        canActivate: [RoleGuard],
        component: SupplierTrackingComponent,
        data: {
            roles: [UserRole.Administrator],
        },
        pathMatch: 'full',
    },
    {
        path: 'supplier-control',
        canActivate: [RoleGuard],
        component: SupplierControlComponent,
        data: {
            roles: [UserRole.SupplierSupervisor],
        },
        pathMatch: 'full',
    },
    {
        path: 'daily-review',
        canActivate: [RoleGuard],
        component: DailyReviewComponent,
        data: {
            roles: [UserRole.Picker, UserRole.Deliverer],
        },
        pathMatch: 'full',
    },
    {
        path: 'knowledge-base',
        canActivate: [RoleGuard],
        data: {
            roles: [UserRole.User],
        },
        component: KnowledgeBaseComponent,
        pathMatch: 'full',
    },
    {
        path: 'app-permission',
        component: PermissionRequestWindowComponent,
        pathMatch: 'full',
    },
]; // sets up routes constant where you define your routes

// configures NgModule imports and exports
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
