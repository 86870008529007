import { Inject, Injectable } from '@angular/core';
import { IOrdersService } from './iorders.service';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Order } from '../../models/order';
import { OrderItem } from '../../models/order-item';
import { OrderItemState } from '../../types/order-item-state';
import { SetOrderItemState } from '../../dtos/set-order-item-state';
import { IUpdateOrderItem } from '../../dtos/i-update-order-item';
import { ICreateReplacementItem } from '../../dtos/i-create-replacement-item';
import { OrderListFilterOptions } from '../../types/order-list-filter-options';
import { ProviderOrderListFilterOptions } from '../../types/provider-order-list-filter-options';
import { CustomerContactData } from '../../models/customer-contact-data';
import { ICreateOrderItem } from '../../dtos/i-create-order-item';
import { OrderDetails } from '../../models/order-details';
import { OrderPaymentRefundResponse } from '../../types/order-payment-refund-response';
import { UpdateCustomerNotes } from '../../dtos/update-customer-notes';
import { UpdateDeliveryFee } from '../../dtos/update-delivery-fee';
import { API_CLIENT_SERVICE_IMPL, IAPIClientService } from '../iapi-client.service';
import { MarkupCorrectionDto } from '../../dtos/markup-correction';
import { OrderHistory } from '../../models/order-history';
import { OrderRemovedItemReasons } from '../../types/order-removed-item-reasons';
import { CreateOrderRemovedItem } from '../../dtos/create-order-removed-item';
import { SupportCommentDto } from '../../dtos/support-comment.dto';
import { IModifyOrderItem } from '../../dtos/imodifyorderitemdto';

@Injectable()
export class OrdersService implements IOrdersService {
    constructor(@Inject(API_CLIENT_SERVICE_IMPL) private _apiClient: IAPIClientService) {}

    public getOrder(orderID: number): Observable<HttpResponse<Order>> {
        return this._apiClient.getWithResponse<Order>(`orders/${orderID}`);
    }

    public changeBillPhotoID(
        orderID: number,
        orderBillPhotoID: string): Observable<HttpResponse<any>> {

        return this._apiClient.postWithResponse<any>('orders/change-bill-photo-id',
            {
                orderID,
                orderBillPhotoID,
            });
    }

    public getOrderItem(orderItemID: number): Observable<OrderItem> {
        return this._apiClient.get<OrderItem>(`orderitems/getone/${orderItemID}`);
    }

    public getOrderItems(orderID: number): Observable<OrderItem[]> {
        return this._apiClient.get<OrderItem[]>(`orderitems/${orderID}`);
    }

    public getOrderByCode(orderCode: string): Observable<HttpResponse<Order>> {
        return this._apiClient.getWithResponse<Order>(
            `orders/order-by-code/${orderCode}`
        );
    }

    public getOrderHistory(orderID: number): Observable<OrderHistory[]> {
        return this._apiClient.get<OrderHistory[]>(`orderhistory/${orderID}`);
    }

    public setOrderItemState(
        orderItemID: number,
        orderItemState: OrderItemState
    ): Observable<HttpResponse<any>> {
        const requestBody = new SetOrderItemState();

        requestBody.orderItemID = orderItemID;
        requestBody.orderItemState = orderItemState;

        return this._apiClient.postWithResponse<any>('orderitems/setstate', requestBody);
    }

    public updateOrderItem(
        requestBody: IUpdateOrderItem
    ): Observable<HttpResponse<void>> {
        return this._apiClient.postWithResponse<void>('orderitems/update', requestBody);
    }

    public createReplacementItem(
        requestBody: ICreateReplacementItem
    ): Observable<HttpResponse<OrderItem>> {
        return this._apiClient.postWithResponse<OrderItem>(
            'orderreplacementitems/create',
            requestBody
        );
    }

    public updateReplacementItem(
        requestBody: ICreateReplacementItem
    ): Observable<HttpResponse<void>> {
        return this._apiClient.putWithResponse<void>(
            'orderreplacementitems/update',
            requestBody
        );
    }

    public getFilterOptions(): Observable<OrderListFilterOptions> {
        return this._apiClient.get<OrderListFilterOptions>(`orders/filteroptions`);
    }

    public getProviderFilterOptions(): Observable<ProviderOrderListFilterOptions> {
        return this._apiClient.get<ProviderOrderListFilterOptions>(
            `providerorders/filteroptions`
        );
    }

    public getCustomerContactData(orderID: number): Observable<CustomerContactData> {
        return this._apiClient.get<CustomerContactData>(
            `orders/customer-contact-data/${orderID}`
        );
    }

    public updateCustomerContactData(
        contactData: CustomerContactData
    ): Observable<HttpResponse<void>> {
        return this._apiClient.postWithResponse<void>(
            'orders/update-customer-contact',
            contactData
        );
    }

    createOrderItem(
        orderItemData: ICreateOrderItem
    ): Observable<HttpResponse<OrderItem>> {
        return this._apiClient.postWithResponse<OrderItem>(
            'orderitems/create',
            orderItemData
        );
    }

    getOrderDetails(orderID: number): Observable<OrderDetails> {
        return this._apiClient.get<OrderDetails>(`orders/order-details/${orderID}`);
    }

    getOriginalOrderWeight(orderID: number): Observable<number> {
        return this._apiClient.get<number>(
            `orders/order-original-total-weight/${orderID}`
        );
    }

    refundOrderPayment(
        orderID: number
    ): Observable<HttpResponse<OrderPaymentRefundResponse>> {
        return this._apiClient.postWithResponse<OrderPaymentRefundResponse>(
            'orders/refund-order-payment',
            orderID
        );
    }

    getAvailableTimeSlots(orderID: number): Observable<HttpResponse<any>> {
        return this._apiClient.postWithResponse<any>(
            'orders/get-available-timeslots',
            orderID
        );
    }

    changeEstimatedDeliveryEnd(
        orderID: number,
        TimeSlot: Date
    ): Observable<HttpResponse<any>> {
        return this._apiClient.postWithResponse<any>(
            'orders/change-estimated-delivery-end',
            {
                orderID,
                TimeSlot,
            }
        );
    }

    changeTimeSlotForDelivery(
        orderID: number,
        timeSlot: Date
    ): Observable<HttpResponse<any>> {
        return this._apiClient.postWithResponse<any>('orders/change-delivery-timeslot', {
            orderID,
            timeSlot,
        });
    }

    updateCustomerNotes(
        customerNotes: UpdateCustomerNotes
    ): Observable<HttpResponse<void>> {
        return this._apiClient.postWithResponse<void>(
            'orders/update-customer-notes',
            customerNotes
        );
    }

    updateSupportComment(
        supportComment: SupportCommentDto
    ): Observable<HttpResponse<void>> {
        return this._apiClient.postWithResponse<void>(
            'orders/update-support-comment',
            supportComment
        );
    }

    updateDeliveryFee(deliveryFee: UpdateDeliveryFee): Observable<HttpResponse<void>> {
        return this._apiClient.postWithResponse<void>(
            'orders/update-delivery-fee',
            deliveryFee
        );
    }

    public getOrderRemovedItemReasons(): Observable<OrderRemovedItemReasons[]> {
        return this._apiClient.get<OrderRemovedItemReasons[]>(
            `orderremoveditems/removed-types`
        );
    }

    public createOrderRemovedItem(
        orderItemData: CreateOrderRemovedItem
    ): Observable<HttpResponse<void>> {
        return this._apiClient.postWithResponse<void>(
            'orderremoveditems/create',
            orderItemData
        );
    }

    public deleteOrderRemovedItem(orderItemID: number): Observable<HttpResponse<void>> {
        return this._apiClient.deleteWithResponse<void>(
            `orderremoveditems/${orderItemID}`
        );
    }

    public timeOutFinished(orderID: number): Observable<HttpResponse<any>> {
        return this._apiClient.postWithResponse<number>(
            `orders/timeoutfinished/${orderID}`
        );
    }

    public collectFinished(orderID: number): Observable<HttpResponse<any>> {
        return this._apiClient.postWithResponse<number>(
            `orders/collectfinished/${orderID}`
        );
    }

    public getCountNotHandledReplacement(
        orderID: number
    ): Observable<HttpResponse<number>> {
        return this._apiClient.getWithResponse<number>(
            `orders/getcountnothandledreplacement/${orderID}`
        );
    }

    public getTimeLeftToModifyOrder(orderID: number): Observable<HttpResponse<Date>> {
        return this._apiClient.getWithResponse<Date>(
            `orders/gettimelefttomodifyorder/${orderID}`
        );
    }

    public deleteReplacementItem(orderItemID: number): Observable<HttpResponse<void>> {
        return this._apiClient.deleteWithResponse<void>(
            `orderreplacementitems/${orderItemID}`
        );
    }

    public getOrderBlockedStatus(orderID: number): Observable<HttpResponse<boolean>> {
        return this._apiClient.getWithResponse<any>(
            `orders/order-blocked-status/${orderID}`
        );
    }

    public correctStockForOrder(
        correctionDto: MarkupCorrectionDto
    ): Observable<HttpResponse<void>> {
        return this._apiClient.postWithResponse<void>(
            'markupcorrection/correct-stock',
            correctionDto
        );
    }

    public correctInvoiceForOrder(
        correctionDto: MarkupCorrectionDto
    ): Observable<HttpResponse<void>> {
        return this._apiClient.postWithResponse<void>(
            'markupcorrection/correct-invoice',
            correctionDto
        );
    }

    public correctInvoiceAndStockForOrder(
        correctionDto: MarkupCorrectionDto
    ): Observable<HttpResponse<void>> {
        return this._apiClient.postWithResponse<void>(
            'markupcorrection/correct-invoice-and-stock',
            correctionDto
        );
    }

    public generateInvoiceJpl(orderID: number): Observable<HttpResponse<Blob>> {
        return this._apiClient.getBlobWithResponse(
            `markupcorrection/generate-invoice-jpl/${orderID}`
        );
    }

    getOrderInvoices(orderID: number): Observable<OrderDetails> {
        return this._apiClient.get<OrderDetails>(`orders/order-invoices/${orderID}`);
    }

    public setCustomerCalled(orderID: number): Observable<HttpResponse<void>> {
        return this._apiClient.postWithResponse<void>(
            `orders/customer-called/${orderID}`
        );
    }

    modifyOrderItem(requestBody: IModifyOrderItem): Observable<HttpResponse<void>> {
        return this._apiClient.postWithResponse<void>(
            `orderitems/modify-orderitem`, requestBody
        );
    }
}
