import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CollectingStatesService } from '../../../collecting/collecting-states.service';
import { IosAndAndroidNatvieFunctionsService } from 'dm-src/services/ios-and-android-natvie-functions/ios-and-android-natvie-functions.service';
import {
    BarcodeReaderState,
    ColorClass,
    FlashMessageService,
    ModalService,
} from 'shared';
import { DepositService } from '../../deposit.service';
import { ReplaySubject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-deposit-voucher-barcode-reader-modal',
    templateUrl: './deposit-voucher-barcode-reader-modal.component.html',
    styleUrls: ['./deposit-voucher-barcode-reader-modal.component.scss'],
})
export class DepositVoucherBarcodeReaderModalComponent implements OnInit, OnDestroy {
    @Output() onBarcodeRead = new EventEmitter<string>();
    private _destroy$: ReplaySubject<boolean>;
    public ColorClass = ColorClass;
    public barcodeReaderState: BarcodeReaderState;

    constructor(
        private _depositService: DepositService,
        private _states: CollectingStatesService,
        private _flashMessageService: FlashMessageService,
        private _modalService: ModalService,
        private _iosAndAndroidNatvieFunctionsService: IosAndAndroidNatvieFunctionsService
    ) {
        this._destroy$ = new ReplaySubject<boolean>(1);
        this.barcodeReaderState = BarcodeReaderState.StandBy;
    }

    ngOnInit(): void {
        this._states.isBarcodeReaderModalVisible
            .pipe(skip(1))
            .pipe(takeUntil(this._destroy$))
            .subscribe((isVisible) => {
                this.setBarcodeReaderState(isVisible);
                this._modalService.setModalVisibility(
                    isVisible,
                    'deposit-voucher-barcode-reader-modal'
                );
            });

        if (window.ReactNativeWebView) {
            this._iosAndAndroidNatvieFunctionsService
                .getCurrentPostMessage()
                .subscribe((response) => {
                    if (response.functionName === 'BARCODE_READING_START') {
                        this._depositService.setVoucherBarcode(response.result);
                        this._modalService.setModalVisibility(
                            true,
                            'deposit-voucher-barcode-checking-modal'
                        );
                    }
                });
        }
    }

    public showErrorMessage(message: string): void {
        this._flashMessageService.showMessage(
            'Error',
            message,
            3000,
            ColorClass.Danger,
            'deposit-voucher-barcode-modal-message'
        );
    }

    public showMessage(message: string): void {
        this._flashMessageService.showMessage(
            null,
            message,
            3000,
            ColorClass.Warning,
            'deposit-voucher-barcode-modal-message'
        );
    }

    public onBarcodeFound(gtin: string): void {
        this.onBarcodeRead.emit(gtin);
    }

    public onClose(): void {
        this.setBarcodeReaderState(false);
    }

    public onXClick(): void {
        this._depositService.resetAll();
        this.setBarcodeReaderState(false);
    }

    private setBarcodeReaderState(isVisible: boolean): void {
        if (isVisible) {
            this.barcodeReaderState = BarcodeReaderState.Reading;
        } else {
            this.barcodeReaderState = BarcodeReaderState.StandBy;
        }
    }

    ngOnDestroy(): void {
        this._destroy$.next(true);
        this._destroy$.complete();
    }
}
