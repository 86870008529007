import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { LoginModule } from './modules/login/login.module';
import { FlashMessageModule } from './modules/flash-message/flash-message.module';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { QRReaderModule } from './modules/qr-reader/qr-reader.module';
import { BtnProgressModule } from './modules/btn-progress/btn-progress.module';
import { ModalModule } from './modules/modal/modal.module';
import { ProgressLineModule } from './modules/progress-line/progress-line.module';
import { ListModule } from './modules/list/list.module';
import { HasRolesDirective } from './directives/has-roles.directive';
import { IsAuthenticatedDirective } from './directives/is-authenticated.directive';
import { CanDirective } from './directives/can.directive';
import { NgbdSortableHeader } from './directives/sortable.directive';
import { HumanCasePipe } from './pipes/human-case.pipe';
import { LibCommonModule } from './modules/lib-common/lib-common.module';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { NavMenuIonicComponent } from './components/nav-menu/navmenu.ionic.component';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { BarcodeReaderModule } from './modules/barcode-reader/barcode-reader.module';
import { NumericDirective } from './directives/numeric.directive';
import { PriceRoundPipe } from './pipes/price-round.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { DecimalPipe } from './pipes/decimal.pipe';
import { PreventDoubleClickDirective } from './directives/prevent-double-click.directive';
import { UserSettingsModule } from './modules/user-settings/user-settings.module';
import { FormsModule } from '@angular/forms';
import { NativeBarcodeReaderInputModule } from './modules/native-barcode-reader-input/native-barcode-reader-input.module';
import { BtnCloseModule } from './modules/btn-close/btn-close.module';
import { QuantityModule } from './modules/product-quantity/quantity.module';
import { NativeTakePhotoResultModule } from './modules/native-take-photo-result/native-take-photo-result.module';
import { SumupCallbackInputModule } from './modules/sumup-callback-input/sumup-callback-input.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
    declarations: [
        NavMenuComponent,
        NavMenuIonicComponent,
        HasRolesDirective,
        IsAuthenticatedDirective,
        CanDirective,
        NgbdSortableHeader,
        HumanCasePipe,
        NumericDirective,
        PriceRoundPipe,
        SafePipe,
        DecimalPipe,
        PreventDoubleClickDirective,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        IonicModule.forRoot(),
        LoginModule,
        ProgressLineModule,
        ModalModule,
        QRReaderModule,
        BtnProgressModule,
        FlashMessageModule,
        HttpClientModule,
        ListModule,
        LibCommonModule,
        FontAwesomeModule,
        RouterModule,
        NgbDropdownModule,
        BarcodeReaderModule,
        UserSettingsModule,
        BtnCloseModule,
        QuantityModule,
        FormsModule,
    ],
    exports: [
        NavMenuComponent,
        NavMenuIonicComponent,
        LoginModule,
        FlashMessageModule,
        ProgressLineModule,
        ModalModule,
        QRReaderModule,
        BarcodeReaderModule,
        BtnProgressModule,
        ListModule,
        TranslateModule,
        UserSettingsModule,
        HasRolesDirective,
        IsAuthenticatedDirective,
        CanDirective,
        NumericDirective,
        NgbdSortableHeader,
        HumanCasePipe,
        PriceRoundPipe,
        SafePipe,
        DecimalPipe,
        LibCommonModule,
        FontAwesomeModule,
        PreventDoubleClickDirective,
        BtnCloseModule,
        QuantityModule,
        NativeBarcodeReaderInputModule,
        SumupCallbackInputModule,
        NativeTakePhotoResultModule,
    ],
})
export class SharedModule {}
