import { Inject, Injectable } from '@angular/core';
import { SaveDepositVoucher } from '../../../dtos/save-deposit-voucher';
import {
    API_CLIENT_SERVICE_IMPL,
    IAPIClientService,
    AUTH_SERVICE_IMPL,
    IAuthenticationService,
} from 'shared';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class DepositService {
    private voucherPhotoContent: string;
    private voucherPhotoExtension: string;
    private orderCode: string;
    private isVoucherPhotoReady: boolean = false;

    private voucherBarcode: string;
    private isVoucherBarcodeScanningReady: boolean = false;

    constructor(
        @Inject(API_CLIENT_SERVICE_IMPL) private _apiClient: IAPIClientService,
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService
    ) {}

    public get currentVoucherBarcode() {
        return this.voucherBarcode;
    }

    public setVoucherPhotoContent(
        voucherPhotoContent: string,
        orderCode: string,
        voucherPhotoExtension: string
    ): void {
        this.orderCode = orderCode;
        this.voucherPhotoContent = voucherPhotoContent;
        this.voucherPhotoExtension = voucherPhotoExtension;
        this.isVoucherPhotoReady = true;
    }

    public setVoucherBarcode(voucherBarcode: string): void {
        this.voucherBarcode = voucherBarcode;
        this.isVoucherBarcodeScanningReady = true;
    }

    public resetAll() {
        this.unsetVoucherBarcode();
        this.unsetVoucherPhotoContent();
    }

    public saveVoucher(): Observable<HttpResponse<string>> {
        let saveDepositVoucher = new SaveDepositVoucher();

        saveDepositVoucher.userID = this._authService.currentUser.userID;
        saveDepositVoucher.depositVoucherPhotoContent = this.voucherPhotoContent;
        saveDepositVoucher.depositVoucherPhotoExtension = this.voucherPhotoExtension;
        saveDepositVoucher.orderCode = this.orderCode;
        saveDepositVoucher.barcode = this.voucherBarcode;

        return this._apiClient.postWithResponse<string>(
            `deposit-voucher/save`,
            saveDepositVoucher
        );
    }

    private unsetVoucherPhotoContent(): void {
        this.voucherPhotoContent = '';
        this.voucherPhotoExtension = '';
        this.isVoucherPhotoReady = false;
    }

    private unsetVoucherBarcode(): void {
        this.voucherBarcode = '';
        this.isVoucherBarcodeScanningReady = false;
    }
}
