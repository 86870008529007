import { Component, Inject, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import {
    ACCOUNTS_SERVICE_IMPL,
    AppSettings,
    IAccountsService,
    UpdateService,
} from 'shared';
import { IsWrappingService } from 'dm-src/services/is-wrapping/is-wrapping.service';
import { environment } from 'dm-src/environments/environment.prod';
import { NavigationEnd, Router } from '@angular/router';
import { IosAndAndroidNatvieFunctionsService } from 'dm-src/services/ios-and-android-natvie-functions/ios-and-android-natvie-functions.service';
import * as Sentry from '@sentry/angular-ivy';
import { fromEvent, merge, of, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
@Sentry.TraceClassDecorator()
export class AppComponent implements OnInit {
    public title = 'Delivery Manager';
    public isWrapping = false;
    isTrackingPage: boolean;
    networkStatus: boolean = false;
    networkStatus$: Subscription = Subscription.EMPTY;
    constructor(
        @Inject(ACCOUNTS_SERVICE_IMPL) private _accountsService: IAccountsService,
        private _swUpdate: SwUpdate,
        private _translate: TranslateService,
        private _router: Router,
        private _isWrappingService: IsWrappingService,
        private _iOSAndAndroidNF: IosAndAndroidNatvieFunctionsService,
        private updateService: UpdateService,
        private _snackBar: MatSnackBar
    ) {
        const defaultLocale =
            localStorage.getItem('defaultLocale') ?? AppSettings.get('defaultLocale');

        console.log('defaultLocale', defaultLocale);
        _translate.setDefaultLang(defaultLocale);
        _translate.use(defaultLocale);
    }

    ngOnInit() {
        this.updateService.checkForUpdates();
        this.checkNetworkStatus();
        if (this._isWrappingService.isWrapping()) {
            this.isWrapping = true;
            this._router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this._iOSAndAndroidNF.getFrontendVersion(environment.appVersion);
                }
            });
        }

        this._router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.isTrackingPage = event.url.includes('tracking');
            }
        });

        if (this._swUpdate.isEnabled) {
            this._swUpdate.versionUpdates.subscribe(() => {
                if (confirm(this._translate.instant('common.new-app-version-released'))) {
                    window.location.reload();
                }
            });
        }

        if (!this._isWrappingService.isWrapping() && !window.ReactNativeWebView) {
            this._accountsService
                .canUseDmInBrowser(false)
                .subscribe((canUseDmInBrowser) => {
                    if (!canUseDmInBrowser.body) {
                        this._router.navigate(['logout']);
                    }
                });
        }

        const userAgent = navigator.userAgent || navigator.vendor;

        if (/android/i.test(userAgent)) {
            document.addEventListener(
                'message',
                (message: any) => {
                    this._iOSAndAndroidNF.setCurrentPostMessage(message?.data);
                },
                false
            );
        }

        if (this._iOSAndAndroidNF.iOS()) {
            window.addEventListener(
                'message',
                (message: any) => {
                    this._iOSAndAndroidNF.setCurrentPostMessage(message?.data);
                },
                false
            );
        }
    }

    ngOnDestroy(): void {
        this.networkStatus$.unsubscribe();
    }

    checkNetworkStatus(): void {
        this.networkStatus = navigator.onLine;
        this.networkStatus$ = merge(
            of(null),
            fromEvent(window, 'online'),
            fromEvent(window, 'offline')
        )
            .pipe(map(() => navigator.onLine))
            .subscribe((status) => {
                if (!status) {
                    this._translate
                        .get('messages.network-connection-not-available')
                        .subscribe((text) => {
                            this._snackBar.open(text, 'OK');
                        });
                }
                this.networkStatus = status;
            });
    }

    pullToRefreshEvent(event: Subject<void>): void {
        document.location.reload();
        event.next();
    }
}
