<div *ngIf="photoLoaded" class="bill-thumbnail-container">
    <img [src]="billImgSrc" />
    <div class="text-center">
        <strong>{{ 'collecting.bill-photo-id-placeholder' | translate }}:</strong>
        {{ billImgID }}
    </div>
</div>
<div class="file-input" *ngIf="!photoLoaded">
    <label class="photo-btn btn btn-block btn-primary" (click)="showBillPhotoPopup()">
        <i class="fa fa-camera"></i>{{ 'collecting.take-a-bill-photo' | translate }}
    </label>
</div>

<lib-modal
    [title]="'collecting.bill-photo-popup-title' | translate"
    [componentID]="'bill-photo-popup'"
    [bgColorClass]="'danger'"
    [hasCloseIcon]="false"
>
    <ng-container>
        <div class="w-100">
            <h2 class="text-left">
                {{ 'collecting.bill-photo-popup-description' | translate }}
            </h2>
        </div>

        <div class="photo-thumbnail-container ml-auto mr-auto">
            <img
                class="img-thumbnail d-block mr-auto ml-auto"
                [src]="
                    !billImgSrc || billImgSrc === '' ? billImgPlaceholderUrl : billImgSrc
                "
            />
        </div>
        <div class="file-input" *ngIf="!photoLoaded">
            <input
                *ngIf="!isNewWrapper"
                type="file"
                accept="image/*"
                capture="environment"
                id="photo-input"
                onclick="this.value = null"
                (change)="onPhotoInputChanged($event)"
                hidden
            />
            <label
                class="photo-btn btn btn-block btn-info"
                for="photo-input"
                (click)="handleTakeAPhoto()"
            >
                <i class="fa fa-camera"></i> {{ 'collecting.photo-upload' | translate }}
            </label>
            <div *ngIf="isBillImgInvalid" class="text-danger text-center">
                <div>
                    {{ 'collecting.photo-upload' | translate }}
                    {{ 'common.is-required' | translate }}
                </div>
            </div>
        </div>
        <hr />
        <div class="w-100 mt-4 mb-4">
            <input
                type="text"
                id="billImgID"
                [(ngModel)]="billImgID"
                class="form-control"
                placeholder="{{ 'collecting.bill-photo-id-placeholder' | translate }}"
                autofocus
            />
            <div *ngIf="isBillImgIDInvalid" class="text-danger text-center">
                <div>
                    {{ 'collecting.bill-photo-id-placeholder' | translate }}
                    {{ 'common.is-required' | translate }}
                </div>
            </div>
        </div>

        <div class="text-center w-100">
            <button
                [disabled]="isBillImgInvalid || isBillImgIDInvalid"
                class="btn btn-success w-75 mb-4"
                (click)="saveBillPhoto()"
            >
                {{ 'common.save' | translate }}
            </button>
            <button
                class="btn btn-secondary w-75"
                (click)="
                    modalService.setModalVisibility(false, 'bill-photo-popup');
                    this.resetAll()
                "
            >
                {{ 'common.cancel' | translate }}
            </button>
        </div>
    </ng-container>
</lib-modal>
