import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-return-deposit',
    templateUrl: './return-deposit.component.html',
    styleUrls: ['./return-deposit.component.scss'],
})
export class ReturnDepositComponent implements OnInit {
    url: string;
    safeUrl: string;

    constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer) {}

    ngOnInit() {
        this.url = decodeURIComponent(window.location.href.split('?depositformurl=')[1]);

        console.log('URL: ', decodeURIComponent(this.url));
    }

    transform(url: any) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
