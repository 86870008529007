<lib-login
    [showCountrySelector]="true"
    [isApplication]="isApplication"
    [osType]="osType"
    (loginClick)="handleLoginClick($event)"
></lib-login>

<div class="text-right">
    <a
        href="javascript:void(0)"
        (click)="handleFrontendVersion()"
        class="link-offset-2 link-underline link-underline-opacity-100"
    >
        Change environment
    </a>
</div>
