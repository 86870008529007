<lib-modal
    [title]="'common.barcode-reading' | translate"
    [componentID]="'deposit-voucher-barcode-reader-modal'"
    [bgColorClass]="ColorClass.Dark"
    [colorClass]="ColorClass.White"
    (onClose)="onClose()"
    (onXClick)="onXClick()"
>
    <lib-flash-message [id]="'deposit-voucher-barcode-modal-message'"></lib-flash-message>
    <lib-barcode-reader
        [state]="barcodeReaderState"
        (onBarcodeRead)="onBarcodeFound($event)"
        (onError)="showErrorMessage($event)"
        (onMessage)="showMessage($event)"
    ></lib-barcode-reader>
</lib-modal>
